import classnames from 'classnames';
import React from 'react';

import SkeletonBlock from 'dpl/common/design_system/SkeletonBlock';
import { makeSequence } from 'dpl/shared/utils';
import { isLessThanBreakpoint } from 'dpl/util/grid';

const IS_LT_MD = isLessThanBreakpoint('md');
const IS_LT_LG = isLessThanBreakpoint('lg');

function getCardCount() {
  if (IS_LT_MD) return 1;
  if (IS_LT_LG) return 2;

  return 3;
}

export default function BreederProfilePastPuppiesSectionSkeleton() {
  return (
    <div className="BreederProfilePastPuppiesSectionSkeleton bg-subtle-light-gray pv12 nb12">
      <div className="container-xxl">
        <div className="mb5 mb6-md">
          <SkeletonBlock
            className="font-20 md:font-24 mb4"
            height="1em"
            width="8em"
          />
          <SkeletonBlock
            className="font-16 md:font-18"
            height="1em"
            width="14em"
          />
        </div>
        <div
          className={classnames('flex gap-16', {
            'flex-column': IS_LT_MD
          })}
        >
          {makeSequence(getCardCount()).map(index => (
            <SkeletonBlock
              key={index}
              className="br3"
              height={IS_LT_LG ? '424px' : '504px'}
              width="100%"
            />
          ))}
        </div>
      </div>
    </div>
  );
}
