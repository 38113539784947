import React from 'react';

import isFeatureEnabled from 'dpl/util/isFeatureEnabled';
import { FEATURES } from 'dpl/shared/constants/features';

import BreederProfileAboutPuppiesSectionContainer from '../BreederProfileAboutPuppiesSectionContainer';
import BreederProfileAdoptionProcessSectionContainer from '../BreederProfileAdoptionProcessSectionContainer';
import BreederProfileCurrentPuppiesSectionContainer from '../BreederProfileCurrentPuppiesSectionContainer';
import BreederProfileDeliverySectionContainer from '../BreederProfileDeliverySectionContainer';
import BreederProfileHealthTestsSectionContainer from '../BreederProfileHealthTestsSectionContainer';
import BreederProfileLittersSectionContainer from '../BreederProfileLittersSectionContainer';
import BreederProfileMainContainer from '../BreederProfileMainContainer';
import BreederProfileMoreAboutBreederSectionContainer from '../BreederProfileMoreAboutBreederSectionContainer';
import BreederProfileOwnerStoriesContainer from '../BreederProfileOwnerStoriesContainer';
import BreederProfileParentsSectionContainer from '../BreederProfileParentsSectionContainer';
import BreederProfilePhotosSectionContainer from '../BreederProfilePhotosSectionContainer';
import BreederProfileRelatedBreedsContainer from '../BreederProfileRelatedBreedsContainer';
import BreederProfileUpdatesSectionContainer from '../BreederProfileUpdatesSectionContainer';

const IS_BREEDER_UPDATES_ENABLED = isFeatureEnabled(
  FEATURES.REL_BREEDER_UPDATES
);

export default function BreederProfileOverviewTabContainer() {
  return (
    <div className="BreederProfileOverviewTabContainer">
      <BreederProfileMainContainer />
      <BreederProfileCurrentPuppiesSectionContainer />
      <BreederProfileLittersSectionContainer />
      {IS_BREEDER_UPDATES_ENABLED && <BreederProfileUpdatesSectionContainer />}
      <BreederProfileAdoptionProcessSectionContainer />
      <BreederProfileAboutPuppiesSectionContainer />
      <BreederProfileDeliverySectionContainer />
      <BreederProfileMoreAboutBreederSectionContainer />
      <BreederProfileParentsSectionContainer />
      <BreederProfileHealthTestsSectionContainer />
      <BreederProfileOwnerStoriesContainer />
      <BreederProfilePhotosSectionContainer />
      <BreederProfileRelatedBreedsContainer />
    </div>
  );
}
