import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useReducer, useState } from 'react';

import ClickableDiv from 'dpl/common/components/ClickableDiv';
import MediaGridCarousel from 'dpl/components/MediaGrid/MediaGridCarousel';
import SmartImage from 'dpl/components/SmartImage';
import UserAvatar from 'dpl/path_to_payment/components/UserAvatar';
import { truncateText } from 'dpl/shared/utils';
import { isLessThanBreakpoint } from 'dpl/util/grid';

const IS_LT_MD = isLessThanBreakpoint('md');
const COLLAPSED_GALLERY_LENGTH = 2;
const COLLAPSED_DESCRIPTION_LENGTH = 200;

export default function OwnerStory({
  className,
  date,
  description,
  gallery,
  user,
  showVerifiedOwnerTag,
  breederProfile
}) {
  const [activeSlideIndex, setActiveSlideIndex] = useState(-1);

  const truncatedDescription = truncateText(
    description,
    COLLAPSED_DESCRIPTION_LENGTH
  );

  const isCollapsible =
    gallery.length > COLLAPSED_GALLERY_LENGTH ||
    description !== truncatedDescription;

  const [isCollapsed, toggleIsCollapsed] = useReducer(
    collapsed => !collapsed,
    isCollapsible
  );

  const thumbnails = isCollapsed
    ? gallery.slice(0, COLLAPSED_GALLERY_LENGTH)
    : gallery;

  return (
    <div className={classnames('OwnerStory', className)}>
      <div className="flex items-center gap-12 md:gap-16 mb5">
        <UserAvatar user={user} size="md" />
        <div className="lh-copy">
          <p className="font-16 fw-medium">{user.name}</p>
          {showVerifiedOwnerTag ? (
            <p className="font-12 secondary">Verified owner &bull; {date}</p>
          ) : (
            <p className="font-14 md:font-16 secondary">{date}</p>
          )}
        </div>
      </div>
      <p className="font-16">
        {isCollapsed ? truncatedDescription : description}
      </p>
      {gallery.length > 0 && (
        <div className="mt4">
          <ul
            className="OwnerStory__gallery grid grid-cols-2 gap-4 md:gap-16"
            style={{ maxWidth: 420 }}
          >
            {thumbnails.map((thumbnail, index) => (
              <li key={thumbnail.id}>
                <ClickableDiv onClick={() => setActiveSlideIndex(index)}>
                  <SmartImage
                    className="w-100 br2 object-cover"
                    loadingClass="bg-light-gray"
                    alt={thumbnail.caption}
                    src={thumbnail.three_two_thumbnail_url}
                    height={IS_LT_MD ? '100px' : '120px'}
                    crop
                  />
                </ClickableDiv>
              </li>
            ))}
          </ul>
          {activeSlideIndex >= 0 && (
            <MediaGridCarousel
              mediaFiles={gallery}
              selectedMediaIdx={activeSlideIndex}
              onClose={() => setActiveSlideIndex(-1)}
            />
          )}
        </div>
      )}
      {isCollapsible && (
        <button
          type="button"
          className="font-16 stone-700 underline pv2 mt2"
          onClick={toggleIsCollapsed}
        >
          {isCollapsed ? 'Show more' : 'Show less'}
        </button>
      )}
      {breederProfile && (
        <div className="flex gap-4 bt b--default pt4 mt4">
          <UserAvatar user={breederProfile} size="sm" />
          <div>
            <p className="font-16">Raised by {breederProfile.name}</p>
            <p className="font-12 secondary">
              of{' '}
              <a
                className="underline"
                href={breederProfile.profile_url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {breederProfile.program_name}
              </a>
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

OwnerStory.propTypes = {
  className: PropTypes.string,
  date: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  gallery: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      type: PropTypes.string,
      caption: PropTypes.string,
      url: PropTypes.string.isRequired,
      three_two_thumbnail_url: PropTypes.string.isRequired
    })
  ).isRequired,
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    initials: PropTypes.string.isRequired,
    profile_photo_url: PropTypes.string
  }).isRequired,
  showVerifiedOwnerTag: PropTypes.bool,
  breederProfile: PropTypes.shape({
    id: PropTypes.number.isRequired,
    program_name: PropTypes.string.isRequired,
    initials: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    profile_photo_url: PropTypes.string,
    profile_url: PropTypes.string.isRequired
  })
};

OwnerStory.defaultProps = {
  className: null,
  showVerifiedOwnerTag: false,
  breederProfile: null
};
