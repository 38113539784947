import classnames from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'validate.js';

import BreederProfileNearbyCities from 'dpl/components/BreederProfile/Index/BreederProfileList/BreederProfileNearbyCities';
import BreederProfileRelatedBreedsSkeleton from 'dpl/components/BreederProfile/Show/skeletons/BreederProfileRelatedBreedsSkeleton';
import RelatedBreedsModule from 'dpl/puppy_search_results/components/v2/RelatedBreedsModule';
import useRelatedBreeds from 'dpl/puppy_search_results/hooks/useRelatedBreeds';

export default function BreederProfileRelatedBreedsContainer() {
  const { breeds, breederProfileId, citySlug, locationTitle } = useSelector(
    ({ server }) => server
  );

  const { isFetching, data: relatedBreedsData } = useRelatedBreeds();

  return (
    <div className="BreederRelatedLinks pv12 pv20-md">
      <div className="container-xxl">
        {isFetching && <BreederProfileRelatedBreedsSkeleton />}
        {!isFetching && !isEmpty(relatedBreedsData) && (
          <div>
            <h2 className="font-24 md:font-40 lh-title mb6 mb8-md">
              Related breeds
            </h2>
            <RelatedBreedsModule className="mb6 mb20-lg" heading="" />
          </div>
        )}
        <div className={classnames({ dn: isFetching })}>
          {Boolean(citySlug || locationTitle) &&
            breeds.map(b => (
              <BreederProfileNearbyCities
                key={b.id}
                breedId={b.id}
                breederProfileId={breederProfileId}
                breedName={b.name}
                limit={10}
                wideLayout
              />
            ))}
        </div>
      </div>
    </div>
  );
}
