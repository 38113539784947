import BreederProfileApplyButtonContainer from 'dpl/containers/BreederProfile/Show/BreederProfileApplyButtonContainer';
import BreederProfileLayoutContainer from 'dpl/containers/BreederProfile/Show/BreederProfileLayoutContainer';
import BreederProfilePhotoAlbumContainer from 'dpl/containers/BreederProfile/Show/BreederProfilePhotoAlbumContainer';
import BreederProfileMainContainer from 'dpl/containers/BreederProfile/Show/BreederProfileMainContainer';
import BreederProfileFollowButtonContainer from 'dpl/containers/BreederProfile/Show/BreederProfileFollowButtonContainer';
import BreederProfileMobileActionsContainer from 'dpl/containers/BreederProfile/Show/BreederProfileMobileActionsContainer';
import BreederProfileQuestionButton from 'dpl/components/BreederProfile/Show/BreederProfileQuestionButton';
import BreederProfileTestimonialModal from 'dpl/containers/BreederProfile/Index/BreederProfileTestimonialModal';
import LitterModal from 'dpl/dog_modals/litter_modal/components/LitterModal';
import NewsletterFormContainer from 'dpl/containers/NewsletterFormContainer';
import ParentModal from 'dpl/dog_modals/parent_modal/components/ParentModal';
import PuppyModal from 'dpl/dog_modals/puppy_modal/components/PuppyModal';
import PreferredBreederBadge from 'dpl/breeder_preferred_qualifications/components/PreferredBreederBadge';
import breederFragmentRedirect from 'dpl/util/breederFragmentRedirect';
import initReactApp from 'dpl/util/initReactApp';
import { ANALYTICS_EVENTS, ANALYTICS_VIEWS } from 'dpl/constants/analytics';
import { sendRudderstackEvent } from 'dpl/util/analytics';
import { setAnalyticsOrigin } from 'dpl/shared/utils/setAnalyticsOrigin';

setAnalyticsOrigin({
  originView: ANALYTICS_VIEWS.BREEDER_PROFILE_PAGE
});

if (!breederFragmentRedirect()) {
  sendRudderstackEvent(
    ANALYTICS_EVENTS.BREEDER_PROFILE_VIEWED,
    window.__DPL_EVENT_PROPS
  );

  initReactApp({
    BreederProfileMainContainer,
    BreederProfileFollowButtonContainer,
    BreederProfileLayoutContainer,
    BreederProfileMobileActionsContainer,
    BreederProfileQuestionButton,
    BreederProfileApplyButtonContainer,
    BreederProfilePhotoAlbumContainer,
    NewsletterFormContainer,
    BreederProfileTestimonialModal,
    PreferredBreederBadge,
    PuppyModal,
    ParentModal,
    LitterModal
  });
}
