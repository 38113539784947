import React from 'react';
import { useSelector } from 'react-redux';

import BreederProfileCurrentLittersSectionContainer from '../BreederProfileCurrentLittersSectionContainer';
import BreederProfileLittersSectionContainer from '../BreederProfileLittersSectionContainer';
import BreederProfilePastPuppiesSectionContainer from '../BreederProfilePastPuppiesSectionContainer';

export default function BreederProfileLittersAndPuppiesTabContainer() {
  const { hasCurrentLitters, hasPastPuppies } = useSelector(
    ({ server }) => server
  );

  return (
    <div className="BreederProfileLittersAndPuppiesTabContainer flex flex-column gap-48">
      <BreederProfileCurrentLittersSectionContainer />
      {hasPastPuppies && <BreederProfilePastPuppiesSectionContainer />}
      {hasCurrentLitters && (
        <BreederProfileLittersSectionContainer
          className="bg-white"
          mobileLayoutType="stacked"
        />
      )}
    </div>
  );
}
