import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'validate.js';

import BreederProfilePastPuppiesCarousel from 'dpl/components/BreederProfile/Show/BreederProfilePastPuppiesCarousel';
import BreederProfilePastPuppiesSectionSkeleton from 'dpl/components/BreederProfile/Show/skeletons/BreederProfilePastPuppiesSectionSkeleton';
import { LITTER_DISPLAY_STATES } from 'dpl/shared/constants/shared';
import useResourceQuery from 'dpl/shared/fetching/hooks/useResourceQuery';
import { nameUnnamedPuppies } from 'dpl/shared/utils/breeder_app';
import { littersResourceDefinition } from 'dpl/shared/utils/tptConnectResourceDefinitions';

export default function BreederProfilePastPuppiesSectionContainer() {
  const { breederProfileId, breederFirstName } = useSelector(
    ({ server }) => server
  );

  const {
    isFetching,
    value: { data: litters }
  } = useResourceQuery(
    littersResourceDefinition(breederProfileId, {
      status: LITTER_DISPLAY_STATES.PAST,
      exclude_adult: true
    })
  );

  const puppies = useMemo(
    () =>
      litters.reduce(
        (pups, litter) => [...pups, ...nameUnnamedPuppies(litter.puppies)],
        []
      ),
    [litters]
  );

  if (isFetching) {
    return <BreederProfilePastPuppiesSectionSkeleton />;
  }

  if (isEmpty(puppies)) {
    return null;
  }

  return (
    <BreederProfilePastPuppiesCarousel
      firstName={breederFirstName}
      breederProfileId={breederProfileId}
      puppies={puppies}
    />
  );
}
